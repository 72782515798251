import React, { Component } from "react"
import './CaseStudyList.less';
import {graphql} from "gatsby";
import Wrapper from '../components/Wrapper.js';
import { Pagination } from 'antd';
import { navigate } from '@reach/router';
import PageTitle from "../modules/PageTitle";
import CaseStudyListItem from "../components/CaseStudyListItem";
import WorkFilter from "../components/WorkFilter";

class CaseStudyList extends Component {

    gotoPage(page){
        if(page===1){
            navigate(`/our-work/`);
        } else {
            navigate(`/our-work/page/${page}`);
        }
    }

    render() {
        const caseStudies = this.props.data.allWordpressWpCaseStudy.edges;
        return (
            <Wrapper
                title="Our work – brands becoming their digital best"
                description="Take a look at the digital marketing campaigns we're proud of, from digital strategy workshops through to scroll-stopping social campaigns and brand-new websites."
                canonical={ this.props.pageContext.current === 1 ? '' : '/our-work/' }
            >
                <PageTitle title="Our work is your problem, solved" />
                <WorkFilter active={'all'}/>
                <div className="case-study-listing">
                    {caseStudies && caseStudies.map(({node}) => {
                        return (
                            <CaseStudyListItem key={node.id} caseStudy={node} />
                        )
                    })}
                </div>
                <Pagination
                    current={this.props.pageContext.current}
                    total={this.props.pageContext.numPosts}
                    pageSize={this.props.pageContext.limit}
                    onChange={this.gotoPage}
                />
            </Wrapper>
        )
    }
}

export const caseStudyListQuery = graphql`
  query caseStudyListQuery($skip: Int!, $limit: Int!) {
    allWordpressWpCaseStudy(limit: $limit, skip: $skip, sort: { order: DESC, fields: [date] }){
      edges {
        node {
        wordpress_id
        id
        date
        slug
        acf {
          project_strapline
          project_colour
          project_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 400) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          services {
            label
            value
          }
        }
      }
    }
  }
 }
`;

export default CaseStudyList


